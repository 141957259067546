export function getDynamicName(report, namingFieldId) {
    if (!Array.isArray(report.sections)) {
        return '';
    }
    for (const section of report.sections) {
        const matchingField = section.fields.find(f => f.id === namingFieldId);
        if (matchingField) {
            const name = matchingField.data;
            if (typeof name !== 'string') {
                console.warn(`namingFieldId ${namingFieldId}: ${name} is not a string`);
                return '';
            }
            return name.replace(/\//g, '_');
        }
        const foundInSubsection = getDynamicName(section, namingFieldId);
        if (foundInSubsection && foundInSubsection !== '') {
            return foundInSubsection;
        }
    }
    return '';
}
